import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { ChevronRight } from "react-feather"

const Container = styled.div`
  height: 480px;
  padding: 20px 20px;
  display: flex;
  border: 1px solid transparent;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.15);

  background-color: #f5f7fa;
  & a,
  p {
    color: #3e4c59;
  }
  border-radius: 10px;

  @media screen and (min-width: 1050px) {
    margin-right: 16px;
    width: 332px;
    transition: all 500ms ease;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1),
      0px 10px 20px rgba(0, 0, 0, 0.15);

    & p {
      font-size: 15px;
    }
    & :hover {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15),
        0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
`
const Title = styled.h1`
  color: #323f4b;
  font-size: 24px;
  font-weight: 900;
  line-height: 140%;
  text-transform: uppercase;
`

const CTA = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const CTAspan = styled.span`
  color: #127fbf;
  margin-right: 5px;
  font-weight: 800;
  @media screen and (min-width: 1050px) {
    font-size: 14px;
  }
`

const ServiceList = styled.div`
  flex: 1;
  & p {
    font-size: 14px !important;
    font-weight: 500;
  }
`

const ServiceCard = ({ title, children }) => {
  return (
    <Link to="/contattaci">
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            padding: "10px 0",
          }}
        >
          <span
            style={{ color: "#323f4b", fontSize: "20px", fontWeight: "200" }}
          >
            area
          </span>
          <Title>{title}</Title>
        </div>
        <hr />
        <ServiceList>{children}</ServiceList>
        <CTA>
          <CTAspan>Contattaci</CTAspan>
          <ChevronRight color="#127FBF" />
        </CTA>
      </Container>
    </Link>
  )
}

export default ServiceCard
